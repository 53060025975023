import React, { useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import styled from "styled-components";
import { IoCall } from "react-icons/io5";

import "react-datepicker/dist/react-datepicker.css";

const Floatbutton = () => {
  const handleCall = () => {
    window.location.href = "tel:+64212955629";
  };
  const WhatsAppButton = styled.a`
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #25d366; /* WhatsApp green color */
    border: none;
    cursor: pointer;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 30px; /* Adjust icon size */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    text-decoration: none; /* Remove underline from link */

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      transform: translateY(-10px);

      color: #ffffff;
    }
  `;

  const ChatbotButton = styled.div`
    position: fixed;
    bottom: 120px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #0078d4; /* Example chatbot button color */
    border: none;
    cursor: pointer;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 30px; /* Adjust icon size */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    text-decoration: none; /* Remove underline from link */

    &:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      transform: translateY(-10px);
      background-color: #005a9e; /* Darker shade of blue on hover */
    }
  `;

  return (
    <>
      <ChatbotButton onClick={handleCall}>
        <IoCall style={{ fontSize: "30px" }} />
      </ChatbotButton>

      <WhatsAppButton href="https://wa.me/+64212955629" target="_blank">
        <ImWhatsapp />
      </WhatsAppButton>
    </>
  );
};

export default Floatbutton;
