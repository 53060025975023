import "../Pages/Home.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setActiveDropdown(null); // Reset active dropdown when closing mobile menu
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    // Implement the search functionality here
    console.log("Search query:", searchQuery);
  };

  const handleCall = () => {
    window.location.href = "tel:+64212955629";
  };
  const handleMail = () => {
    window.location.href = "mailto:cndhearing@gamil.com";
  };

  return (
    <>
      <div
        className={`site-mobile-menu site-navbar-target ${
          isMobileMenuOpen ? "active" : ""
        }`}
        data-spy="scroll"
        data-target=".site-navbar-target"
        data-offset="300"
      >
        <div className="site-mobile-menu-header">
          <div
            className="site-mobile-menu-close mt-3"
            onClick={toggleMobileMenu}
          >
            <span className="icon-close2 js-menu-toggle"></span>
          </div>
        </div>
        <div className="">
          <ul className="site-menu main-menu js-clone-nav mr-auto d-lg-none mt-5">
            <li>
              <Link to="/" className="nav-link" onClick={toggleMobileMenu}>
                Home
              </Link>
            </li>
            <li
              className={`has-children ${activeDropdown === 0 ? "active" : ""}`}
            >
              <Link
                to="#"
                className="nav-link"
                onClick={() => toggleDropdown(0)}
              >
                Services
              </Link>
              <ul
                className="dropdown"
                style={{
                  display: activeDropdown === 0 ? "block" : "none",
                }}
              >
                <li>
                  <Link
                    to="/ear-suction"
                    onClick={toggleMobileMenu}
                    className="dropdown-li"
                  >
                    Ear Suction Speclist In Ackland
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/about-us"
                className="nav-link"
                onClick={toggleMobileMenu}
              >
                About
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                className="nav-link"
                onClick={toggleMobileMenu}
              >
                Contact
              </Link>
            </li>
            <li onClick={handleCall}>Call Now</li>
          </ul>
        </div>
      </div>
      <div className="border-bottom top-bar py-2 bg-dark" id="home-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="mb-0 header-navcontact">
                <span
                  className="mr-3"
                  style={{ color: "#2c7bfe" }}
                  onClick={handleCall}
                >
                  <strong className="text-white">Phone:</strong> +64212955629
                </span>
                <span style={{ color: "#2c7bfe" }} onClick={handleMail}>
                  <strong className="text-white">Mail:</strong>{" "}
                  cndhearing@yahoo.com
                </span>
              </p>
            </div>
            <div className="col-md-6">
              <ul className="social-media header-navcontact">
                <li>
                  <Link to="#" className="p-2">
                    <span className="icon-facebook"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="p-2">
                    <span className="icon-twitter"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="p-2">
                    <span className="icon-instagram"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="p-2">
                    <span className="icon-linkedin"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <header
        className="site-navbar py-0 py-md-4 bg-light js-sticky-header site-navbar-target"
        role="banner"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-10 col-xl-2">
              <h1 className="mb-0 site-logo">
                <Link to="/" className="text-black h2 mb-0">
                  <img src="https://cndhearingsolution.co.nz/assets/images/logo-1.png"></img>
                </Link>
              </h1>
            </div>

            <div
              className="d-inline-block d-xl-none ml-md-0 mr-auto py-3"
              style={{ position: "relative", top: "3px" }}
            >
              <button
                className="site-menu-toggle js-menu-toggle text-black"
                onClick={toggleMobileMenu}
              >
                <span className="icon-menu h3"></span>
              </button>
            </div>

            <div className="col-12 col-md-10 d-none d-xl-block">
              <nav
                className="site-navigation position-relative text-right"
                role="navigation"
              >
                <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                  <li>
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="has-children">
                    <Link
                      to="#"
                      className="nav-link"
                      onClick={() => toggleDropdown(0)}
                    >
                      Service
                    </Link>
                    <ul
                      className="dropdown"
                      style={{
                        display: activeDropdown === 0 ? "block" : "none",
                      }}
                    >
                      <li>
                        <Link to="/ear-suction/">
                          Ear Suction Speclist In Auckland
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/about-us/" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact/" className="nav-link">
                      Contact Us
                    </Link>
                  </li>

                  {/* <li>
                    <Link to="/Booknow" className="nav-link">
                      Book Now
                    </Link>
                  </li> */}
                  <li
                    className="btn btn-md header-booknow"
                    onClick={handleCall}
                  >
                    <p>Call Now</p>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
