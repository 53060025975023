// import {
//   BrowserRouter,
//   Route,
//   Switch,
// } from "react-router-dom/cjs/react-router-dom.min";
// import "./App.css";
// import Home from "./Components/Pages/Home";
// import About from "./Components/Pages/About";
// import Contactus from "./Components/Pages/Contactus";
// import Header from "./Components/Main/Header";
// import Footer from "./Components/Main/Footer";
// import Products from "./Components/Pages/Dropdown/Earsuction";
// import Earsuction from "./Components/Pages/Dropdown/Earsuction";

// function App() {
//   return (
//     <>
//       <BrowserRouter>
//         <Header />
//         <Switch>
//           <Route exact path="/" component={Home} />
//           <Route path="/about-us" component={About} />
//           <Route path="/contact" component={Contactus} />
//           <Route path="/ear-suction" component={Earsuction} />
//         </Switch>
//         <Footer />
//       </BrowserRouter>
//     </>
//   );
// }

// export default App;
import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import "./App.css";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Contactus from "./Components/Pages/Contactus";
import Header from "./Components/Main/Header";
import Footer from "./Components/Main/Footer";
import Earsuction from "./Components/Pages/Dropdown/Earsuction";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about-us/" component={About} />
          <Route path="/contact/" component={Contactus} />
          <Route path="/ear-suction/" component={Earsuction} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
