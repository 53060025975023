import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiLocationMarker } from "react-icons/hi";
import { IoCall } from "react-icons/io5";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  // Effect to update the current year once when component mounts
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);
  const handleCall = () => {
    window.location.href = "tel:+64212955629";
  };
  return (
    <>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-5">
                  <h2 className="footer-heading mb-4 ">CND HEARING SOLUTION</h2>
                  <p className="text-justify">
                    CND Hearing Solutions Ltd is Independently (NZ) owned. The
                    company was started in 2014 by Chris Joseph who is an NZAS
                    Qualified Audiometrist and the Director of the company.
                  </p>
                </div>
                <div className="col-md-3 ml-auto">
                  <h2 className="footer-heading mb-4">Features</h2>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/ear-suction">Services</a>
                    </li>
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <h2 className="footer-heading mb-4">Address</h2>
                  <p>
                    <HiLocationMarker
                      style={{ color: "white", fontSize: "29px" }}
                    />
                    10,Tiverton Road, Avondale,
                    <br></br> Auckland- 0600.
                  </p>
                  <p onClick={handleCall}>
                    <IoCall style={{ color: "white", fontSize: "29px" }} />
                    +64212955629
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <h2 className="footer-heading mb-4">Follow Us</h2>
              <a href="#" className="pl-0 pr-3">
                <span className="icon-facebook"></span>
              </a>
              <a href="#" className="pl-3 pr-3">
                <span className="icon-twitter"></span>
              </a>
              <a href="#" className="pl-3 pr-3">
                <span className="icon-instagram"></span>
              </a>
              <a href="#" className="pl-3 pr-3">
                <span className="icon-linkedin"></span>
              </a>
            </div>
          </div>
          <div className="row pt-5 mt-5 text-center">
            <div className="col-md-12">
              <div className="border-top pt-5">
                <p>
                  &copy; Copyright{" "}
                  <span style={{ color: "#999999" }}>CND HEARING SOLUTION</span>{" "}
                  {currentYear} All rights reserved |{" "}
                  <i className="icon-heart text-danger" aria-hidden="true"></i>{" "}
                  Desgined by{" "}
                  <a
                    href="https://colorlib.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Branding world
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
