import React from "react";
import Floatbutton from "../Functions/Floatbutton";
import { IoEarOutline } from "react-icons/io5";
import { Helmet } from "react-helmet";

const About = () => {
  const handleCall = () => {
    window.location.href = "tel:+64212955629";
  };
  return (
    <>
      <Helmet>
        <title>Ear Wax Removal Throughout Auckland | CND Hearing</title>
        <meta
          name="description"
          content="CND Hearing Solution is now trending as one of the highly reputed Ear Wax Removal Throughout Auckland at affordable cost - Contact Now @ 021–2955629"
        />
      </Helmet>
      <div className="site-section mt-5" id="about-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-5 ml-auto mb-5 order-md-1" data-aos="fade">
              <img
                src="/assets/images/cnd-about.jpg"
                alt="Image"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-md-6 order-md-2" data-aos="fade">
              <div className="row">
                <div className="col-12">
                  <div className="text-left pb-1">
                    <h2 className="text-black h1 site-section-heading">
                      About Us
                    </h2>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <p className="lead">
                    CND Hearing Solutions Ltd is Independently (NZ) owned . The
                    company was started in 2014 by Chris Joseph who is an NZAS
                    Qualified Audiometrist and the Director of the company. He
                    is in the Audiology field since 1989.. We do ear wax removal
                    by Ear Suction.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-5 ml-auto  order-md-1" data-aos="fade">
              <h3>Professional assessment and treatment for:</h3>
              <hr></hr>
              <p>Wax removal</p>
              <hr></hr>
              <p>Blocked ears</p>
              <hr></hr>
              <p>Itchy ears</p>
              <hr></hr>
              <p>Swimmer’s ear</p>
              <hr></hr>
              <p>Surfer’s ear</p>
              <hr></hr>
            </div>
            <div className="col-md-6 order-md-2" data-aos="fade">
              <div className="row">
                <div className="col-12">
                  <div className="text-left pb-1">
                    <h2 className="text-black h1 site-section-heading">
                      Contact Us
                    </h2>
                  </div>
                </div>
                <div className="col-12 mb-4 ">
                  <h4>Timing:</h4>
                  <p style={{ fontWeight: "bold" }}>
                    Week Days from 6.00PM to 8.00PM <br></br> Saturday and
                    Sunday from 10.00AM to 3.00PM
                  </p>
                  <h4>Address:</h4>
                  <h6>10,Tiverton Road, Avondale, Auckland- 0600.</h6>
                  <br></br>
                  <h4>Phone:</h4>
                  <h5 onClick={handleCall}>+64212955629</h5>
                  <br></br>
                  <p
                    onClick={handleCall}
                    class="btn header-booknow  d-flex justify-content-center"
                    to="/Booknow"
                    target="_self"
                  >
                    Schedule Your Appointment
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-md-5 mb-0 ">
            <div className="unit-4">
              <div className="unit-4-icon mr-4 mb-3">
                <IoEarOutline style={{ fontSize: "30px" }} />
              </div>
              <div className="text-justify">
                <h3>
                  Do You Know Everything About Ear Wax Removal Throughout
                  Auckland?
                </h3>
                <p>
                  Ear wax elimination offerings are effortlessly on hand at some
                  point of Auckland, catering to people experiencing soreness or
                  impaired listening to due to extra ear wax buildup. Auckland
                  boasts several clinics, clinical centers, and professional
                  practices providing expert ear wax elimination processes
                  carried out via educated audiologists or ear care specialists.
                </p>
                <p>
                  {" "}
                  These offerings commonly make use of secure and wonderful
                  strategies to eliminate extra ear wax, such as microsuction,
                  irrigation, or guide elimination the use of specialised
                  instruments. Microsuction includes the usage of a mild suction
                  system to extract wax from the ear canal barring the use of
                  water, making it appropriate for folks with touchy ears or
                  these inclined to ear infections.
                </p>
                <p>
                  {" "}
                  Irrigation, additionally regarded as ear syringing, includes
                  flushing the ear canal with a mild move of heat water to
                  dislodge and cast off wax buildup. Manual elimination might
                  also contain the usage of specialised equipment like curettes
                  or loops to cautiously extract wax from the ear canal
                  underneath magnification.
                </p>
                <p>
                  Patients looking for ear wax elimination offerings in Auckland
                  can count on professional, customized care tailor-made to
                  their person needs, with most clinics presenting handy
                  appointment scheduling and environment friendly processes to
                  make certain minimal pain and most effectiveness. It’s really
                  helpful to seek advice from with a healthcare expert or ear
                  care expert to decide the most appropriate technique for ear
                  wax elimination primarily based on character situations and
                  any underlying ear condition.
                </p>
                <p>
                  Logical examinations have not yet pinpointed with regards to
                  why we have earwax. Earwax is created by organs in the ear
                  trench. Earwax helps in catching residue and other miniature
                  particles and keeps them from coming to or possibly
                  harming/tainting the eardrum.
                </p>
                <p>
                  In typical conditions the wax dries and drops out of the ear
                  alongside the caught residue and particles. Another intriguing
                  truth is that how much ear wax created is hereditary like our
                  hair tone or stature. Now and then the more modest or
                  strangely molded ear trenches make it hard for the ear wax to
                  escape the waterway and this causes earwax blockage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Floatbutton />
    </>
  );
};

export default About;
